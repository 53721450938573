import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Header from "components/Header/Header.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";

import dg1 from "assets/img/dg1.jpg";

function SectionHeaders({ ...props }) {
  const { classes, ...rest } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      {/* HEADER 3 START */}
      <div>
        <Header
          absolute
          brand="Toronto Tech Company"
          color="transparent"
          links={
            <List className={classes.list + " " + classes.mlAuto}>
              <ListItem className={classes.listItem}>
                <Button
                  href="#home"
                  className={classes.navLink}
                  color="transparent"
                >
                  Home
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  href="#service"
                  className={classes.navLink}
                  color="transparent"
                >
                  Services
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  href="#about"
                  className={classes.navLink}
                  color="transparent"
                >
                  Team
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button
                  href="#contacts"
                  className={classes.navLink}
                  color="transparent"
                >
                  Contact us
                </Button>
              </ListItem>
            </List>
          }
        />
        <Carousel {...settings}>
          {/* Carousel 1 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg1}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <h1 className={classes.title}>Design | Develop | Transform</h1>
                    <h4>
                      Custom software solutions that help you optimize operations and accelerate growth.
                    </h4>
                    <br />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 1 END */}
        </Carousel>
      </div>
      {/* HEADER 3 END */}
    </div>
  );
}

SectionHeaders.propTypes = {
  classes: PropTypes.object
};

export default withStyles(headersStyle)(SectionHeaders);
