import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ColorLens from "@material-ui/icons/ColorLens";
import Airplay from "@material-ui/icons/Airplay";
import Transform from "@material-ui/icons/Transform";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.jsx";

function SectionFeatures({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 2 START */}
        <div className={classes.features2}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>What We Do</h2>
              <h5 className={classes.description}>
                Our team fosters lean, agile and scalable software development that is committed to delivering unique and powerful solutions.  
                <br/>
                <br/>
                We do web and mobile application design and development. We can help you uplift your current software or build custom solutions from the ground up.              
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={ColorLens}
                title="Design"
                description={
                  <span>
                    <p>
                      From UI/UX and wireframes to high fidelity product
                      designs, our team of experts create human experiences
                      that leave an impact.
                    </p>
                  </span>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={Airplay}
                title="Develop"
                description={
                  <span>
                    <p>
                      From mobile and web applications to e-commerce and custom
                      software, our team uses agile methodologies to build lean
                      products your customers will love.
                    </p>
                  </span>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={Transform}
                title="Transform"
                description={
                  <span>
                    <p>
                      We want to partner with you and create transformative
                      experiences that will help you accelerate growth,
                      adapt and change.
                    </p>
                  </span>
                }
                iconColor="success"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 2 END */}
      </div>
    </div>
  );
}

SectionFeatures.propTypes = {
  classes: PropTypes.object
};

export default withStyles(featuresStyle)(SectionFeatures);
