import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// sections of this Page
import SectionHeaders from "./Sections/SectionHeaders.jsx";
import SectionFeatures from "./Sections/SectionFeatures.jsx";
import SectionBlogs from "./Sections/SectionBlogs.jsx";
import SectionTeams from "./Sections/SectionTeams.jsx";
import SectionProjects from "./Sections/SectionProjects.jsx";
import Footer from "./Sections/Footer.jsx";
import SectionContacts from "./Sections/SectionContacts.jsx";

import sectionsPageStyle from "assets/jss/material-kit-pro-react/views/sectionsPageStyle.jsx";

import ReactGA from 'react-ga';
ReactGA.initialize("UA-146023637-1");
ReactGA.pageview(window.location.pathname + window.location.search);


class SectionsPage extends React.Component {
  componentDidMount() {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0)
      document.getElementById(href).scrollIntoView();
    window.addEventListener("scroll", this.updateView);
    this.updateView();

    const script = document.createElement("script");
    script.id = "hs-script-loader";
    script.src = "https://js.hs-scripts.com/6292384.js";
    script.async = true;
    document.body.appendChild(script);

  //   <!-- Start of HubSpot Embed Code -->
  // <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6292384.js"></script>
  // <!-- End of HubSpot Embed Code -->
  }
  componentDidUpdate() {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.pathname !== "/") {
      document.getElementById(href).scrollIntoView();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateView);
  }
  easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }
  updateView() {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  }
  smoothScroll(target) {
    var targetScroll = document.getElementById(target);
    this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  }
  scrollGo(element, to, duration) {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }.bind(this);
    animateScroll();
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.main}>
          <SectionHeaders id="home" />
          <SectionFeatures id="service" />
          <SectionTeams id="about" className="light-grey-bg" />
          <SectionProjects id="projects" />
          {/*
            <SectionTestimonials id="testimonials" />
          */}
          <SectionBlogs id="blogs" className="light-grey-bg" />
          <SectionContacts id="contacts" />
        </div>
        <nav id="cd-vertical-nav">
          <ul>
            <li>
              <a
                href="#home"
                data-number="1"
                className="is-selected"
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("home");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Home</span>
              </a>
            </li>
            <li>
              <a
                href="#service"
                data-number="2"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("service");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Services</span>
              </a>
            </li>
            <li>
              <a
                href="#about"
                data-number="4"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("about");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">About</span>
              </a>
            </li>
            <li>
              <a
                href="#projects"
                data-number="5"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("projects");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Clients</span>
              </a>
            </li>
            <li>
              <a
                href="#blogs"
                data-number="3"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("blogs");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Blogs</span>
              </a>
            </li>
            <li>
              <a
                href="#contacts"
                data-number="7"
                className=""
                onClick={e => {
                  var isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                  );
                  if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                  } else {
                    e.preventDefault();
                    this.smoothScroll("contacts");
                  }
                }}
              >
                <span className="cd-dot" />
                <span className="cd-label">Contact Us</span>
              </a>
            </li>
          </ul>
        </nav>
        <Footer />
      </div>
    );
  }
}

SectionsPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(sectionsPageStyle)(SectionsPage);
