import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.jsx";

function SectionPreFooter(props) {
  const { classes } = props;
  const dateYear = () => {
    const date = new Date();
    return date.getFullYear();
  };
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.title}>
          <h4>© {dateYear()} Toronto Tech Company </h4>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(SectionPreFooter);