import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Muted from "components/Typography/Muted.jsx";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.jsx";

import pawan from "assets/img/faces/PawanGiri.jpg";
import malvika from "assets/img/faces/MalvikaRao.jpg";
import tamle from "assets/img/faces/TamLe.jpg";
import gagan from "assets/img/faces/GD.jpg";

function SectionTeams({ ...props }) {
  const { classes, ...rest } = props;
  const openNewTab = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };
  
  return (
    <div className="cd-section" {...rest}>
      {/* Team 1 START */}
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Core Team</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <img src={pawan} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Pawan Giri</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Founder / CEO</h6>
                  </Muted>
                  <p className={classes.description}>
                    Designer, developer, animator, tech-consultant; this
                    superhero is a man of many talents. But he discovered his
                    real superpower when he became an entrepreneur.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/giripawan/"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    onClick={e => openNewTab(e, "https://www.linkedin.com/in/giripawan/")}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <img src={malvika } alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Malvika Rao</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Content Marketing Head</h6>
                  </Muted>
                  <p className={classes.description}>
                    Jack of all trades, this award-winning wordsmith did her
                    time in traditional advertising before making the
                    lucrative move to content marketing and planning.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/malvika-rao-839b26b0"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    onClick={e => openNewTab(e, "https://www.linkedin.com/in/malvika-rao-839b26b0")}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <img src={tamle} alt="..." className={classes.img} />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Tam Le</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Chief Developer</h6>
                  </Muted>
                  <p className={classes.description}>
                    A hardcore developer with years of programming experience,
                    Tam also holds mentoring workshops on various programming
                    languages at ‘Canada Learning Code’.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/letam/"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    onClick={e => openNewTab(e, "https://www.linkedin.com/in/letam/")}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <Card profile plain>
                <CardAvatar profile plain>
                  <img src={gagan} alt="Gagan Dhanjal" />
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Gagan Dhanjal</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>Technical Sales Head</h6>
                  </Muted>
                  <p className={classes.description}>
                    Having consulted with Fortune 500 companies in the past,
                    Gagan has extensive experience in business development,
                    technical recruitment and market penetration.
                  </p>
                </CardBody>
                <CardFooter profile plain className={classes.justifyContent}>
                  <Button
                    href="https://www.linkedin.com/in/gagandhanjal/"
                    justIcon
                    simple
                    color="linkedin"
                    className={classes.btn}
                    onClick={e => openNewTab(e, "https://www.linkedin.com/in/gagandhanjal/")}
                  >
                    <i className="fab fa-linkedin" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 1 END */}
    </div>
  );
}

SectionTeams.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamsStyle)(SectionTeams);
