import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.jsx";

import deloitte from "assets/img/DeloitteDigital.png";
import tdBank from "assets/img/TDBank.png";
import drg from "assets/img/DRG.png";

function SectionProjects({ ...props }) {
  const { classes, ...rest } = props;
  return (
    <div className="cd-section" {...rest}>
      {/* Project 1 START */}
      <div className={classes.projects}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>
                Our Clients
              </h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <CardHeader image plain>
                <a onClick={e => e.preventDefault()}>
                  <img src={deloitte} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${deloitte})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CardHeader image plain>
                <a onClick={e => e.preventDefault()}>
                  <img src={tdBank} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${tdBank})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CardHeader image plain>
                <a onClick={e => e.preventDefault()}>
                  <img src={drg} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${drg})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 1 END */}
    </div>
  );
}

SectionProjects.propTypes = {
  classes: PropTypes.object
};

export default withStyles(projectsStyle)(SectionProjects);
