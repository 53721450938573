import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Eamil from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.jsx";

import city from "assets/img/examples/city.jpg";
import HubspotForm from 'react-hubspot-form'

class SectionContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: []
    };
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className="cd-section" {...rest}>
        {/* Contact us 1 START */}
        <div
          className={classes.contacts + " " + classes.section}
          style={{ backgroundImage: `url(${city})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <h2 className={classes.title}>Let’s work together.</h2>
                <h5 className="text-light">
                  We will be happy to answer any queries regarding our services, price points or anything else. 
                </h5>
                <InfoArea
                  className={classes.infoArea}
                  title="Find us at the office"
                  description={
                    <span>
                      1 King Street West, suite 4800-217
                      <br /> Toronto, ON M5H 1A1
                      <br /> Canada
                    </span>
                  }
                  icon={PinDrop}
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Email us"
                  description={
                    <span>
                      sales@torontotechcompany.com
                    </span>
                  }
                  icon={Eamil}
                />
              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <Card className={classes.card1}>
                  <form>
                    <CardHeader
                      contact
                      color="primary"
                      className={classes.textCenter}
                    >
                      <h4 className={classes.cardTitle}>Contact Us</h4>
                    </CardHeader>
                    <CardBody>
                      <HubspotForm 
                        portalId="6292384"
                        formId="12c2bdb4-8b17-463f-9ab6-c9137b85d210"
                        loading={<div>Loading...</div>}
                      />
                    </CardBody>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        {/* Contact us 1 END */}
      </div>
    );
  }
}

SectionContacts.propTypes = {
  classes: PropTypes.object
};

export default withStyles(contactsStyle)(SectionContacts);
