import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.jsx";

import arWeatherApp from "assets/img/arWeatherApp.jpg";

function SectionBlogs({ ...props }) {
  const { classes, ...rest } = props;
  
  const openNewTab = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 1 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>Latest From Our Founder</h2>
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#" 
                        onClick={e => openNewTab(e, "https://www.linkedin.com/pulse/i-made-ar-app-now-what-pawan-giri/")}>
                        <img src={arWeatherApp} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${arWeatherApp})`,
                          opacity: "1"
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <h3 className={classes.cardTitle}>
                      <a href="#" 
                        onClick={e => openNewTab(e, "https://www.linkedin.com/pulse/i-made-ar-app-now-what-pawan-giri/")}>
                        I made an AR App, now what?
                      </a>
                    </h3>
                    <p className={classes.description1}>
                      My exciting affair with Augmented Reality (AR) started on
                      June 5, 2017, with Apple’s announcement of introducing the
                      ARKit for developers worldwide. I knew instantaneously,
                      that this was where all my years of experience in 3D ... 
                      <a href="#" 
                        onClick={e => openNewTab(e, "https://www.linkedin.com/pulse/i-made-ar-app-now-what-pawan-giri/")}>
                        {" "}
                        Read More{" "}
                      </a>
                    </p>
                    <p className={classes.author}>
                      by{" "}
                      <a href="#"
                        onClick={e => openNewTab(e, "https://www.linkedin.com/in/giripawan/")}>
                        <b>Pawan Giri</b>
                      </a>
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Blogs 1 END */}
    </div>
  );
}

SectionBlogs.propTypes = {
  classes: PropTypes.object
};

export default withStyles(blogsStyle)(SectionBlogs);
